import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import get from 'lodash/get';
import { graphql } from 'gatsby';
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allMarkdownRemark.edges');
    return (
      <Layout location={this.props.location}>
        <SEO title="Blog" />
        {posts.map(({ node }) => {
          if (this.props.intl.locale === node.frontmatter.lang) {
            const title = get(node, 'frontmatter.title') || node.fields.slug
            return (
                <div key={node.fields.slug}>
                  <h3>
                    <Link style={{ boxShadow: 'none' }} to={node.fields.slug}>
                      {title}
                    </Link>
                  </h3>
                  <small>{node.frontmatter.date}</small>
                  <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                </div>
            )
          }
        })}
      </Layout>
    )
  }
}

export default injectIntl(BlogIndex)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            lang
          }
        }
      }
    }
  }
`